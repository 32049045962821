<template>
  <div id="app">
    <Navbar />
    <Home />
    <About />
    <Projects />
    <Footer />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import Navbar from '/src/components/Navbar.vue'
import Home from '/src/components/Home.vue'
import About from '/src/components/About.vue'
import Projects from '/src/components/Projects.vue'
import Footer from '/src/components/Footer.vue'



export default defineComponent({
  components: { Navbar, Home, About, Projects, Footer },
});  
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');
*{
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;  
  font-family: 'Roboto', sans-serif;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
