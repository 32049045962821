
import { defineComponent } from 'vue';
import { verifyThemeActive, darkTheme } from '../utils/index'

export default defineComponent({
  name: 'Footer-component',
  components: {},
  setup(){
    verifyThemeActive()
    return{
      darkTheme
    }
  }
});
