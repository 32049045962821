
import { defineComponent, ref } from 'vue';
import { changeTheme, verifyThemeActive, darkTheme } from '../utils/index'
export default defineComponent({
  name: 'Navbar-Menu',
  components: {},
  setup () {
    const openNavbar = ref(false)

    const toggleNavbar = () => {
      return openNavbar.value = !openNavbar.value
    }

    verifyThemeActive()

    const scrollPageTo = (navEl: string) => {
      console.log(`#${navEl}`);
      let element = document.querySelector(`#${navEl}`);
      console.log(element);
      if(element){
        element.scrollIntoView({ behavior: "smooth" });
      }
    };       

    return{
      toggleNavbar,
      openNavbar,
      changeTheme,
      darkTheme,
      scrollPageTo
    }
  }
});
