import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "modal-projects",
  class: "overlay"
}
const _hoisted_2 = { class: "popup" }
const _hoisted_3 = { class: "tech-list" }
const _hoisted_4 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.project.name), 1),
      _createElementVNode("p", _hoisted_3, "Tech: " + _toDisplayString(_ctx.formatTechItems(_ctx.project.tech)), 1),
      _createElementVNode("a", {
        class: "close",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
      }, "×"),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("p", null, _toDisplayString(_ctx.project.description), 1)
      ])
    ])
  ]))
}