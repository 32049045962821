
import { defineComponent } from 'vue';
import { verifyThemeActive, darkTheme } from '../utils/index'

export default defineComponent({
  name: 'HomeView',
  components: {},
  setup(){
    verifyThemeActive()
    const urlDownload = 'https://drive.google.com/u/1/uc?id=1Z8AVrn-gdq1AZLziM5Dt-zAIjfPeMVvs&export=download'
    return{
      darkTheme,
      urlDownload
    }
  }
});
