
import { defineComponent, ref, watch } from 'vue';
import { getData } from '../firebase/dbConfig'
import { selectedExperience } from '../types/index'
import { verifyThemeActive, darkTheme } from '../utils/index'

export default defineComponent({
  name: 'AboutView',
  components: {},
  setup() {
    const myExperiences = ref()
    const skills = ['Javascript', 'Vue JS', 'Nuxt JS', 'React JS', 'Quasar', 'Vuetify', 'Bootstrap', 'CSS', 'SCSS']
    const firstSelected = ref(0)
    const xpSelected: selectedExperience[] = []

    const getExperiences = async () => {
      const queryName = 'experiences'
      myExperiences.value = ref(await getData(queryName))
    }    
    getExperiences()

    const changeXpSelected = (item: selectedExperience, index: number) => {
      xpSelected.length = 0
      firstSelected.value = index
      xpSelected.push(item)      
    }

    watch(() => myExperiences.value, (a, b) => {
      console.log('test a: ', a.value[0])
      const item = a.value[0]
      const index = 0
      changeXpSelected(item, index)
    })

    verifyThemeActive()

    return {
      myExperiences,
      skills,
      firstSelected,
      changeXpSelected,
      xpSelected,
      darkTheme
    }
  }
});
