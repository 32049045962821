import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { id: "area-about" }
const _hoisted_2 = { id: "area-skills" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { id: "area-experience" }
const _hoisted_5 = { class: "cards-infos-xp" }
const _hoisted_6 = { class: "area-btn-xp" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "area-infos-xp" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: "about",
    class: _normalizeClass({'about-dark': _ctx.darkTheme, 'about-light': !_ctx.darkTheme})
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h1", null, [
        _createElementVNode("span", {
          class: _normalizeClass({'text-dark': _ctx.darkTheme, 'text-light': !_ctx.darkTheme})
        }, "Sobre", 2)
      ]),
      _createElementVNode("p", {
        class: _normalizeClass(["txt-about", {'text-dark': _ctx.darkTheme, 'text-light': !_ctx.darkTheme}])
      }, " Meu nome é Marco Antonio, tenho 25 anos de idade, moro em Sâo Paulo - SP. Sou graduado em Análise e Desenvolvimento de Sistemas pela Universidade Nove de Julho. Tenho como objetivo principal atuar na área de programação, mais precisamente com Desenvolvimento Web Front-End. Até o momento já tive experiência com a criação de diversas aplicações web, seja sistemas de gerenciamento ou sites. ", 2),
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.skills, (skill, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "btn-skills",
            key: index
          }, _toDisplayString(skill), 1))
        }), 128))
      ])
    ]),
    (_ctx.myExperiences)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("h1", null, [
              _createElementVNode("span", {
                class: _normalizeClass({'text-dark': _ctx.darkTheme, 'text-light': !_ctx.darkTheme})
              }, "Experiências", 2)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.myExperiences.value, (experience, index) => {
                  return (_openBlock(), _createElementBlock("span", {
                    class: _normalizeClass(["btn-experience", {'bg-selected-dark': index === _ctx.firstSelected && _ctx.darkTheme, 'bg-selected-light': index === _ctx.firstSelected && !_ctx.darkTheme, 'text-dark': _ctx.darkTheme, 'text-light': !_ctx.darkTheme}]),
                    key: index,
                    onClick: ($event: any) => (_ctx.changeXpSelected(experience, index))
                  }, _toDisplayString(experience.title), 11, _hoisted_7))
                }), 128))
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("h3", {
                  class: _normalizeClass({'text-dark': _ctx.darkTheme, 'text-light': !_ctx.darkTheme})
                }, _toDisplayString(_ctx.xpSelected[0].cargo) + " - " + _toDisplayString(_ctx.xpSelected[0].title), 3),
                _createElementVNode("h4", {
                  class: _normalizeClass({'text-dark': _ctx.darkTheme, 'text-light': !_ctx.darkTheme})
                }, _toDisplayString(_ctx.xpSelected[0].time_in_service), 3),
                _createElementVNode("p", {
                  class: _normalizeClass({'text-dark': _ctx.darkTheme, 'text-light': !_ctx.darkTheme})
                }, _toDisplayString(_ctx.xpSelected[0].description), 3)
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 2))
}