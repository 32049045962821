import { initializeApp } from "firebase/app"; 
import { getFirestore, getDocs, collection, query, orderBy } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyAbvW_kIhNrnZ0MygqvDFgqXyOtknbucjs",
  authDomain: "db-portfolio-vue3.firebaseapp.com",
  projectId: "db-portfolio-vue3",
  storageBucket: "db-portfolio-vue3.appspot.com",
  messagingSenderId: "364232812959",
  appId: "1:364232812959:web:e0ec50005c48cfab0cf5aa"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp)

export const getData = async (queryName: string) => {
  const colRef = collection(db, queryName)
  const orderColRef = query(colRef, orderBy('date', 'desc'))
  const data: string[] = [];

  const getAllDocs = await getDocs(orderColRef)
  //eslint-disable-next-line
  getAllDocs.docs.forEach((doc: Record<string, any>) => {
    data.push({ ...doc.data() })
  })

  return data
}