
import { defineComponent, ref, computed } from 'vue';

export default defineComponent({
  name: 'ModalProject',
  props: {
    statusModal: {
      type: Boolean,
      required: true
    },
    changeStatusModal: {
      type: Function,
      required: true
    },
    projectSelectedAfterClick: {
      type: Object,
      required: true
    }
  },
  components: {},
  setup(props) {
    const closeModal = () => {
      props.changeStatusModal()
    }
    const project = ref(props.projectSelectedAfterClick)

    const formatTechItems = computed(() => {
      return (item: []) => {
        return item ? item.join(', ') : ''
      }
    })

    return {
      closeModal,
      project,
      formatTechItems
    }
  }
});
