
import { defineComponent, ref, computed } from 'vue';
import { getData } from '../firebase/dbConfig'
import ModalViewProject from '../components/ModalViewProject.vue'
import { projectSelected } from '../types/index'
import { verifyThemeActive, darkTheme } from '../utils/index'

export default defineComponent({
  name: 'ProjectsView',
  components: {ModalViewProject},
  setup () {
    let myProjects = ref()

    const getProjects = async () => {
      const queryName = 'projects'
      myProjects.value = ref(await getData(queryName))
    }

    getProjects()  

    const formatTechItems = computed(() => {
      return (item: []) => {
        return item ? item.join(', ') : ''
      }
    })

    const statusModal = ref(false)
    let projectSelectedAfterClick = ref({});

    const changeStatusModal = (project: projectSelected) => {
      statusModal.value = !statusModal.value
      projectSelectedAfterClick.value = project
    }

    verifyThemeActive()

    return{ 
      myProjects,
      formatTechItems,
      changeStatusModal,
      statusModal,
      projectSelectedAfterClick,
      darkTheme
    }
  }
});
