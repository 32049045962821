import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "area-icons" }
const _hoisted_2 = {
  href: "https://github.com/Marcoantonio9",
  target: "_blank"
}
const _hoisted_3 = {
  href: "https://www.linkedin.com/in/marcoantonio1997/",
  target: "_blank"
}
const _hoisted_4 = {
  href: "https://api.whatsapp.com/send?phone=5511954692896",
  target: "_blank"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa = _resolveComponent("fa")!

  return (_openBlock(), _createElementBlock("div", {
    id: "footer",
    class: _normalizeClass({'footer-dark': _ctx.darkTheme, 'footer-light': !_ctx.darkTheme})
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("a", _hoisted_2, [
        _createVNode(_component_fa, {
          icon: ['fab', 'github'],
          class: _normalizeClass(["icons-sociais", {'icon-dark': _ctx.darkTheme, 'icon-light': !_ctx.darkTheme}])
        }, null, 8, ["class"])
      ]),
      _createElementVNode("a", _hoisted_3, [
        _createVNode(_component_fa, {
          icon: ['fab', 'linkedin'],
          class: _normalizeClass(["icons-sociais", {'icon-dark': _ctx.darkTheme, 'icon-light': !_ctx.darkTheme}])
        }, null, 8, ["class"])
      ]),
      _createElementVNode("a", _hoisted_4, [
        _createVNode(_component_fa, {
          icon: ['fab', 'whatsapp'],
          class: _normalizeClass(["icons-sociais", {'icon-dark': _ctx.darkTheme, 'icon-light': !_ctx.darkTheme}])
        }, null, 8, ["class"])
      ])
    ])
  ], 2))
}