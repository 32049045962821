import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "nav-bar" }
const _hoisted_2 = { class: "logo navLogo" }
const _hoisted_3 = { class: "logo-toggle" }
const _hoisted_4 = { class: "logo" }
const _hoisted_5 = { class: "nav-links" }
const _hoisted_6 = { class: "darkLight-area" }
const _hoisted_7 = { class: "dark-light" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa = _resolveComponent("fa")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["nav", {'nav-active': _ctx.openNavbar, 'dark-menu': _ctx.darkTheme, 'light-menu': !_ctx.darkTheme}])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_fa, {
        icon: "bars",
        class: _normalizeClass(["sidebarOpen", {'link-a-router-dark': _ctx.darkTheme, 'link-a-router-light': !_ctx.darkTheme}]),
        onClick: _ctx.toggleNavbar
      }, null, 8, ["onClick", "class"]),
      _createElementVNode("span", _hoisted_2, [
        _createElementVNode("a", {
          href: "#",
          class: _normalizeClass({'link-a-router-dark': _ctx.darkTheme, 'link-a-router-light': !_ctx.darkTheme, 'border-dark': _ctx.darkTheme, 'border-light': !_ctx.darkTheme})
        }, "Marco Antonio", 2)
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["menu", {'nav-active': _ctx.openNavbar, 'dark-menu': _ctx.darkTheme, 'light-menu': !_ctx.darkTheme}])
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, [
            _createElementVNode("a", {
              href: "#",
              class: _normalizeClass({'link-a-router-dark': _ctx.darkTheme, 'link-a-router-light': !_ctx.darkTheme, 'border-dark': _ctx.darkTheme, 'border-light': !_ctx.darkTheme})
            }, "MarcoAntonio", 2)
          ]),
          _createVNode(_component_fa, {
            icon: "close",
            class: _normalizeClass(["sidebarClose", {'link-a-router-dark': _ctx.darkTheme, 'link-a-router-light': !_ctx.darkTheme}]),
            onClick: _ctx.toggleNavbar
          }, null, 8, ["onClick", "class"])
        ]),
        _createElementVNode("ul", _hoisted_5, [
          _createElementVNode("li", null, [
            _createElementVNode("a", {
              href: "#",
              class: _normalizeClass(["link-a-router", {'link-a-router-dark': _ctx.darkTheme, 'link-a-router-light': !_ctx.darkTheme}])
            }, "Home", 2)
          ]),
          _createElementVNode("li", {
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.scrollPageTo('area-about')), ["prevent"]))
          }, [
            _createElementVNode("a", {
              href: "#",
              class: _normalizeClass(["link-a-router", {'link-a-router-dark': _ctx.darkTheme, 'link-a-router-light': !_ctx.darkTheme}])
            }, "Sobre", 2)
          ]),
          _createElementVNode("li", {
            onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.scrollPageTo('area-experience')), ["prevent"]))
          }, [
            _createElementVNode("a", {
              href: "#",
              class: _normalizeClass(["link-a-router", {'link-a-router-dark': _ctx.darkTheme, 'link-a-router-light': !_ctx.darkTheme}])
            }, "Experiências", 2)
          ]),
          _createElementVNode("li", {
            onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.scrollPageTo('projects')), ["prevent"]))
          }, [
            _createElementVNode("a", {
              href: "#",
              class: _normalizeClass(["link-a-router", {'link-a-router-dark': _ctx.darkTheme, 'link-a-router-light': !_ctx.darkTheme}])
            }, "Projetos", 2)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              (!_ctx.darkTheme)
                ? (_openBlock(), _createBlock(_component_fa, {
                    key: 0,
                    icon: ['fa', 'moon'],
                    class: _normalizeClass(["moon-icon", {'icon-dark': _ctx.darkTheme, 'icon-light': !_ctx.darkTheme}]),
                    onClick: _ctx.changeTheme
                  }, null, 8, ["class", "onClick"]))
                : (_openBlock(), _createBlock(_component_fa, {
                    key: 1,
                    icon: ['fa', 'sun'],
                    class: _normalizeClass({'icon-dark': _ctx.darkTheme, 'icon-light': !_ctx.darkTheme}),
                    onClick: _ctx.changeTheme
                  }, null, 8, ["class", "onClick"]))
            ])
          ])
        ])
      ], 2)
    ])
  ], 2))
}