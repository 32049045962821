import { ref } from 'vue';

const darkTheme = ref(true)

const changeTheme = () => {
    darkTheme.value = !darkTheme.value
    localStorage.setItem('dark_theme', JSON.stringify(darkTheme.value))
}

const verifyThemeActive = () => {
    const getLocalStorageTheme = localStorage.getItem('dark_theme')
    if(!getLocalStorageTheme){
      return;
    }

    darkTheme.value = JSON.parse(getLocalStorageTheme)
  }

export{
    changeTheme,
    verifyThemeActive,
    darkTheme
}